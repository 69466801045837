(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

angular.module('resetPassword', ['restangular', 'ngCookies', 'pascalprecht.translate', 'angular-ladda', 'validation', 'helpers']);

angular.module('resetPassword').config(['RestangularProvider', function (RestangularProvider) {
    RestangularProvider.setBaseUrl('/api/v1'); // check errorInterceptor
    RestangularProvider.setDefaultHeaders({
        'Content-Type': 'application/json'
    });
}]).run(['$rootScope', '$log', '$http', '$timeout', '$translate', '$window', 'Restangular', 'queueService', 'errorService', 'spinnerService', function ($rootScope, $log, $http, $timeout, $translate, $window, Restangular, queueService, errorService, spinnerService) {

    Restangular.addFullRequestInterceptor(function (element, method, name, url, headers) {
        headers['Content-Language'] = $translate.use();
        return {
            "headers": headers
        };
    });

    var removeNulls = function removeNulls(data) {
        var retArr = {};
        if (!angular.isObject(data) && !angular.isArray(data)) {
            retArr = data;
        } else {
            angular.forEach(data, function (value, key) {
                if (angular.isArray(value)) {
                    var tmp = [];
                    angular.forEach(value, function (v) {
                        tmp.push(removeNulls(v));
                    });
                    retArr[key] = tmp;
                } else if (!_.isNull(value) && angular.isObject(value)) {
                    retArr[key] = removeNulls(value);
                } else if (!_.isNull(value)) {
                    retArr[key] = value;
                }
            });
        }
        return retArr;
    };

    var removeNullsFromResponse = function removeNullsFromResponse(data) {
        if (angular.isArray(data)) {
            var retArr = [];
            angular.forEach(data, function (d) {
                retArr.push(removeNulls(d));
            });
            return retArr;
        } else if (angular.isObject(data)) {
            return removeNulls(data);
        } else {
            return {};
        }
    };

    Restangular.addResponseInterceptor(function (data, method, name) {
        $log.debug(method + " " + name + ": success");
        $log.debug(data);
        return removeNullsFromResponse(data);
    });

    Restangular.addErrorInterceptor(function (data) {
        if (angular.isUndefined(data.config)) {
            return;
        }
        $log.debug(data.config.method + " " + data.config.url + ": error");
        $log.debug(data.data);

        spinnerService.endAll();

        if (angular.isObject(data.data)) {
            errorService.clear();
            errorService.addFromServer(data.data);
        }
        return data.data;
    });
}]);

// config

angular.module('resetPassword').config(['$translateProvider', 'version', function ($translateProvider, version) {
    // Register a loader for the static files
    // So, the module will search missing translation tables under the specified urls.
    // Those urls are [prefix][langKey][suffix].
    $translateProvider.useStaticFilesLoader({
        prefix: '../m/l10n/resetPassword/',
        suffix: '.json?version=' + version
    });
    // Set sanitize strategy for more security
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    // Tell the module what language to use by default
    $translateProvider.preferredLanguage('cs');
    // Tell the module to store the language in the local storage
    $translateProvider.useLocalStorage();
}]);
'use strict';

angular.module('resetPassword').controller('PasswordResetCtrl', ['$scope', '$log', '$timeout', '$location', 'errorService', 'spinnerService', 'passwordResetService', function ($scope, $log, $timeout, $location, errorService, spinnerService, passwordResetService) {

    $scope.hasError = errorService.hasError;
    $scope.getErrors = errorService.get;
    $scope.isLoading = spinnerService.isLoading;
    $scope.isInitializing = true;
    $scope.isValid = false;

    $scope.user = {};
    $scope.passwords = {
        first: '',
        second: ''
    };

    var isResetting = false;
    $scope.isResetting = function () {
        return isResetting;
    };

    var url = $location.absUrl();
    var parsedUrl = url.split('/');
    var token = parsedUrl[parsedUrl.length - 1];

    passwordResetService.getUser(token, function (user) {
        $scope.user = user;
        $scope.isInitializing = false;
        $scope.isValid = true;
    }, function () {
        $scope.isInitializing = false;
        $scope.isValid = false;
    });

    $scope.resetPassword = function () {
        $log.debug('PasswordResetCtrl.resetPassword()');
        isResetting = true;
        passwordResetService.resetPassword({
            'email': $scope.user.email,
            'token': token,
            'password': $scope.passwords.first,
            'password_confirmation': $scope.passwords.second
        }, function () {
            isResetting = false;
        });
    };

    $log.debug('PasswordResetCtrl initialized.');
}]);
/**
 * Manage loading spinners.
 *
 * @function start  Starts loading.
 * @function end  Ends loading.
 * @function endAll  Ends all loadings.
 * @function isLoading  Returns if resource is waiting for server.
 * @author tomas
 */
angular.module('resetPassword').service('passwordResetService', ['$log', '$window', 'Restangular', 'errorService', 'spinnerService', 'validEmailFactory', function ($log, $window, Restangular, errorService, spinnerService, validEmailFactory) {
    $log.debug("passwordResetService initialized");

    var sendResetPasswordNotification = function sendResetPasswordNotification(data, callback) {
        errorService.clear();
        if (validateResetPasswordEmailForm(data)) {
            spinnerService.start('access', 'password_send');
            Restangular.all('reset').one('send').post('', data).then(function () {
                spinnerService.end('access', 'password_send');
                if (angular.isFunction(callback)) {
                    callback.call();
                }
            });
        }
    };

    var getUser = function getUser(token, callback, errorCallback) {
        spinnerService.start('access', 'password_validate');
        Restangular.all('reset').one('validate').post('', {
            'token': token
        }).then(function (user) {
            spinnerService.end('access', 'password_validate');
            if (angular.isFunction(callback)) {
                callback({
                    "id": parseInt(user.id),
                    "name": user.name,
                    "profession": user.profession,
                    "phone": user.phone,
                    "photo": user.photo,
                    "email": user.email,
                    "gender": user.gender
                });
            }
        }, function () {
            if (angular.isFunction(errorCallback)) {
                errorCallback();
            }
        });
    };

    var resetPassword = function resetPassword(data, errorCallback) {
        errorService.clear();
        if (validateResetPasswordForm(data)) {
            spinnerService.start('access', 'password_reset');
            Restangular.all('reset').one('password').post('', data).then(function (auth) {
                spinnerService.end('access', 'password_reset');
                if (angular.isUndefined(auth.studio_id)) {
                    auth.studio_id = 0;
                }
                $window.location.href = '/dashboard/' + auth.token + '/' + auth.studio_id;
            }, function () {
                spinnerService.end('access', 'password_reset');
                if (angular.isFunction(errorCallback)) {
                    errorCallback();
                }
            });
        }
    };

    var validateResetPasswordEmailForm = function validateResetPasswordEmailForm(data) {
        $log.debug('Validating reset password email form: ' + JSON.stringify(data) + '.');
        var valid = true;
        if (angular.isUndefined(data.email) || data.email.length === 0 || !validEmailFactory(data.email)) {
            errorService.add('email', 'auth.EMAIL_WRONG_FORMAT');
            valid = false;
        }
        return valid;
    };

    var validateResetPasswordForm = function validateResetPasswordForm(data) {
        $log.debug('Validating reset password form: ' + JSON.stringify(data) + '.');
        var valid = true;
        if (angular.isUndefined(data.password) || data.password.length === 0) {
            errorService.add('password', 'auth.PASSWORD_REQUIRED');
            valid = false;
        }
        if (data.password.length < 8 || data.password.search(/\d/) === -1 || data.password.search(/[A-Z]/) === -1) {
            errorService.add('password', 'auth.PASSWORD_NOT_VALID');
            valid = false;
        }
        if (angular.isUndefined(data.password_confirmation) || data.password_confirmation.length === 0) {
            errorService.add('password_confirmation', 'auth.PASSWORD_REQUIRED');
            valid = false;
        }
        if (data.password_confirmation !== data.password) {
            errorService.add('password_confirmation', 'auth.PASSWORD_NOT_EQUAL');
            valid = false;
        }
        return valid;
    };

    return {
        'sendResetPasswordNotification': sendResetPasswordNotification,
        'getUser': getUser,
        'resetPassword': resetPassword
    };
}]);

},{}]},{},[1]);
